@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  scroll-behavior: smooth;
  background-color: #2d2f33;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(189, 189, 189, 0.5);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  transition: all 0.2s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
      background-color: rgba(189, 189, 189, 1);
}

#gradient-yamen {
  --gradient-color-1: #1F9EA3; 
  --gradient-color-2: #F8BD97; 
  --gradient-color-3: #9E5428;  
  --gradient-color-4: #3B0102;
  height: 80%;
}

#gradient-foodie {
  --gradient-color-1: #2D3E50; 
  --gradient-color-2: #01C26E; 
  --gradient-color-3: #E24C3F;  
  --gradient-color-4: #F3C40F;
}

#gradient-booker {
  --gradient-color-1: #3f2b96; 
  --gradient-color-2: #F8BD97; 
  --gradient-color-3: #a8c0ff;  
  --gradient-color-4: #3B0102;
  height: 80%;
}

a {
  color: #5acaa4;
}

.App {
  text-align: center;
  font-family: 'Roboto Mono', monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media screen and (max-width: 360px) {
  #gradient-booker{
    height: 90%;
  }
}

@media screen and (min-width: 361px) and (max-width: 375px) {
  #gradient-booker{
    height: 100%;
  }
}

@media screen and (min-width: 376px) and (max-width: 393px) {
  #gradient-booker{
    height: 80%;
  }
}

@media screen and (min-width: 394px) and (max-width: 414px) {
  #gradient-booker{
    height: 77%;
  }
}

@media screen and (min-width: 415px) and (max-width: 425px) {
  #gradient-booker{
    height: 85%;
  }
}

@media screen and (min-width: 416px) and (max-width: 540px) {
  #gradient-booker{
    height: 100%;
  }
}


@media screen and (min-width: 768px) and (max-width: 819px) {
  #gradient-booker{
    height: 90%;
  }
}

@media screen and (min-width: 912px) and (max-width: 1024px) {
  #gradient-booker{
    height: 75%;
  }
}

@media screen and (min-width: 913px) and (max-width: 1300px) {
  #gradient-booker{
    height: 70%;
  }
}

.blur{
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px) sepia(0.5);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbarLink {
  --c: #409176; /* the color */
  color: #5acaa4;
  padding: 1rem;
  font-size: 1.5rem;
  text-decoration: none;
  background: 
    linear-gradient(90deg,#fff 50%,var(--c) 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) 100% no-repeat;
  -webkit-background-clip: text,padding-box;
  background-clip: text,padding-box;
  transition: .3s;
}

.navbarLink:hover {
  --_p: 100%;
  color: #25262A;
}

.navbarIcon{
  background-color: #5acaa4;
  color: #25262A;
  width: 1.5rem;
  height: 1.5rem;
  padding: 1rem;
  border-radius: 50%;
  font-size: 1.5rem;
  transition: .3s;
}

.navbarIcon:hover{
  background-color: #409176;
  color: #5acaa4;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
